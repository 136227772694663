<template>
  <div class="electric-invoice-photo page-container">
    <PageTitle title="電子發票 拍照登錄" />
    <p class="electric-invoice-photo_content mb-9">
      上傳之電子發票照片需清晰顯示<br />
      「發票號碼」、「發票開立時間」、「發票隨機碼」，若資訊不足導致無法辨識，<br />
      請再重新上傳或拍照進行審核
    </p>
    <!-- Photo Upload -->
    <div class="photo_upload_item">
      <label for="img">
        <div class="photo_upload_btn">請點此上傳或拍攝發票照片</div>
      </label>
      <input
        type="file"
        name="img"
        id="img"
        hidden
        accept="image/png, image/jpeg"
        @change="uploadPreiview($event)"
      />
      <div class="photo_upload_box">
        <img v-if="url === ''" src="@/assets/img/uploaddemo.jpg" alt="">
        <img v-if="url !== ''" :src="url" />
      </div>
    </div>
    <BaseBtn text="送出登錄資料" :disabled="!isValid" @click="submit" />
    <BaseBtn
      text="重新選擇登錄發票類型"
      type="back"
      :isOutlined="true"
      color="primary"
      @click="routeTo('/InvoiceLog')"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import BaseBtn from "@/components/BaseBtn.vue";

export default {
  name: "ElectricInvoice",
  components: {
    PageTitle,
    BaseBtn,
  },
  data() {
    return {
      url: "",
    };
  },
  computed: {
    isValid() {
      return !!this.url;
    },
  },
  methods: {
    async uploadPreiview(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.url = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    routeTo(route) {
      this.$router.push(route);
    },
    async submit() {
      this.$store.commit("updateLoading", true);
      let url = process.env.VUE_APP_APIUrl + "api/v1/users/get-user-token";
      let data = {
        lineToken: window.localStorage.getItem("userToken"),
      };
      await this.axios
        .post(url, data)
        .then((res) => {
          window.localStorage.setItem("jwt", res.data.data.token);
          let url =
            process.env.VUE_APP_APIUrl +
            "api/v1/auth/invoices/identify-electric-invoice";
          let data = {
            image: this.url,
          };
          this.axios
            .post(url, data, {
              headers: {
                Authorization: "Bearer " + window.localStorage.getItem("jwt"),
              },
            })
            .then((res) => {
              console.log(res.data.data);
              if (res.data.data.failMessage !== "") {
                this.$store.commit("updateInvoice", {
                  hasData: false,
                  data: {},
                });
                this.$store.commit("updateLoading", false);
                this.$router.push({ path: "result", query: { result: "6" } });
              } else {
                this.$store.commit("updateInvoice", {
                  hasData: true,
                  data: res.data.data,
                });
                console.log(this.$store.state.invoice);
                this.$store.commit("updateLoading", false);
                this.$router.push({ path: "electricInvoiceHand" });
              }
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit("updateLoading", false);
              this.$router.push({ path: "result", query: { result: "5" } });
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss">
.electric-invoice-photo {
}
</style>
